import React, { useState, useContext, useEffect } from "react"

import Layout from "../components/layout"
import Slide from "../components/slide"
import { Link } from "gatsby"
import LinkFade from '../components/LinkFade';
import { graphql } from "gatsby"
import { LanguageContext } from "../components/language"

// import 'swiper/swiper.scss';
// import 'swiper/components/navigation/navigation.scss';
// import 'swiper/components/scrollbar/scrollbar.scss';

import SwiperCore, { Controller, Parallax, Scrollbar, Pagination, Navigation, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Controller, Parallax, Scrollbar, Pagination, Navigation, EffectFade]);

const Slideshow = ({ data, pageContext, location, entry }) => {
  const { language, setLanguage } = useContext(LanguageContext);
  const scrollBar = React.createRef();
  const [captionSlider, setCaptionSlider] = useState(null);
  const [sidebarSlider, setSidebarSlider] = useState(null);
  const [mainSlider, setMainSlider] = useState(null);

  const hasMultipleSlides = data && data.strapiSlideshows && data.strapiSlideshows.Slideshow && data.strapiSlideshows.Slideshow.length > 1;

  const toggleLanguageButtons = (activeSlide) => {
    console.log("TOGGLE");

    if (activeSlide.TitleMaori || activeSlide.BodyMaori) {
      console.log("showing");
      document.getElementById("buttons-language").classList.remove("hidden");
      document && document.getElementById("buttons-language").setAttribute('style', 'animation: fadeIn 0.8s ease forwards;');
    } else {
      console.log("hiding");
      // document.getElementById("buttons-language").classList.add("hidden");
      document && document.getElementById("buttons-language").setAttribute('style', 'animation: fadeOut 0.8s ease forwards;');
    }
  }

  if (data === null || data.strapiSlideshows === null)
    return <></>

  if (entry.state && entry.state.opts && entry.state.opts.length > 1) {
    var backOpts = entry.state.opts;
  } else {
    var backOpts = "back";
  }

  return <Layout className="slideshow-page" homePath={pageContext.homePath} timeoutOverride={data.strapiSlideshows.TimeoutOverrideSeconds} showLanguageButtons={pageContext.showLanguageButtons} styles={pageContext.styles}>
    <div className="zoom-btn-fixed" onClick={() => {
      var slideToOpen = document.querySelector(".swiper-slide-active .main-slide a");
      if (slideToOpen !== null) { slideToOpen.click(); }
    }}></div>

    <nav className="nav">
      <LinkFade key={Math.random()} url={`${pageContext.backPath}`} opts={backOpts}>
        <div className="back"></div>
      </LinkFade>
     {/* <Link className="home" to={pageContext.homePath}></Link> */}
    </nav>
    {/* <pre>{JSON.stringify(slideIndex, null, 4)}</pre> */}
    {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
    <>


      <Swiper className="swiper-main"
        autoHeight={false}
        simulateTouch={true}
        preventInteractionOnTransition={true}
        allowSlidePrev={hasMultipleSlides}
        allowSlideNext={hasMultipleSlides}
        loop={false}
        slidesPerView={1}
        speed={1200}
        navigation={
          !hasMultipleSlides ? false : {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }
        }
        onSwiper={(swiper) => {
          // var activeSlide = data.strapiSlideshows.Slideshow[swiper.activeIndex];
          // console.log("HERE");
          // console.log(activeSlide);
          // toggleLanguageButtons(activeSlide);
          setMainSlider(swiper)
        }}
        scrollbar={{
          el: ".swiper-scrollbar",
          draggable: true,
          // preventClicks: true,
          // preventClicksPropagation: true
        }}
        pagination={{
          el: '.swiper-pagination',
          dynamicBullets: false,
          type: 'fraction',
        }}
        onTransitionEnd={() => {
          document.querySelectorAll(".text").forEach((el) => {el.scrollTop = 0});
        }}
        controller={{ control: sidebarSlider }}
        >
          <div className={"swiper- " + (hasMultipleSlides ? "" : "hidden")}></div>
          { data.strapiSlideshows.Slideshow && data.strapiSlideshows.Slideshow.map((slide, i) => {
            // return <pre>{JSON.stringify(slide, null, 4)}</pre>

            return <SwiperSlide key={Math.random()}><Slide key={Math.random() + "_" + slide.id} data={slide} mode="slide"></Slide></SwiperSlide>
          })}
      </Swiper>
      {/* <div ref={scrollBar} className="swiper-scrollbar"></div> */}

      <Swiper className="swiper-caption"
        preventInteractionOnTransition={true}
        fadeEffect={{ crossFade: true }}
        allowTouchMove={false}

        // allowSlidePrev={hasMultipleSlides}
        // allowSlideNext={hasMultipleSlides}

        effect={"fade"}
        // onSlideChange={() => {changeTo("caption")}}
        // controller={{ control: mainSlider }}
        onSwiper={setCaptionSlider}
        loop={false}>
          { data.strapiSlideshows.Slideshow && data.strapiSlideshows.Slideshow.map((slide, i) => {
            // return <pre>{JSON.stringify(slide, null, 4)}</pre>

            return <SwiperSlide key={Math.random()}><Slide key={Math.random() + "_" + slide.id} data={slide} mode="caption"></Slide></SwiperSlide>
          })}
          <div className={"swiper-scrollbar " + (hasMultipleSlides ? "" : "hidden")}><span></span></div>
          <div className={"swiper-arrows " + (hasMultipleSlides ? "" : "hidden")}>
            <div className="swiper-button-prev"><span></span></div>
            <div className="swiper-button-next"><span></span></div>
          </div>
      </Swiper>

      <Swiper className="swiper-infobox"
        speed={600}
        preventInteractionOnTransition={true}
        // allowSlidePrev={hasMultipleSlides}
        // allowSlideNext={hasMultipleSlides}
        allowTouchMove={false}
        loop={false}
        direction={"vertical"}
        // onSwiper={setSidebarSlider}
        onSwiper={setSidebarSlider}
        onSlideChange={(x) => {
          var activeSlide = data.strapiSlideshows.Slideshow[x.activeIndex];
          console.log("change")
          toggleLanguageButtons(activeSlide);
        }}
        parallax={true}
        controller={{ control: captionSlider }}
        >
        { data.strapiSlideshows.Slideshow && data.strapiSlideshows.Slideshow.map((slide, i) => {
          // return <pre>{JSON.stringify(slide, null, 4)}</pre>
          var slideCounter = ""
          if (hasMultipleSlides) {
            slideCounter = (i+1) + "/" + data.strapiSlideshows.Slideshow.length;
          }
          return <SwiperSlide key={Math.random()}><Slide key={Math.random() + "_" + slide.id} data={slide} counter={slideCounter} mode="sidebar"></Slide></SwiperSlide>
        })}
        <div className={"swiper-pagination " + (hasMultipleSlides ? "" : "hidden")}></div>
      </Swiper>
    </>
  </Layout>
}

export const pageQuery = graphql`
  query SlideshowPageQuery($id: String = "") {
    strapiSlideshows(id: {eq: $id}) {
      Slideshow {
        Body
        BodyMaori
        Credit
        MediaCaption
        Title
        TitleMaori
        PlayIconStyle
        id
        Media {
          localFile {
            publicURL
          }
          mime
        }
        VideoPlaceholderImage {
          localFile {
            publicURL
          }
          mime
        }
      }
      Title
      id
      strapiId
      TimeoutOverrideSeconds
    }
  }
`

export default Slideshow
